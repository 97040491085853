import React, { useState, useEffect } from 'react';
import { Coffee, Timer, Mail } from 'lucide-react';

const Portfolio = () => {
  const [counter, setCounter] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prev) => (prev >= 3 ? 1 : prev + 1));
    }, 800);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-amber-50 to-orange-100 flex flex-col items-center justify-center p-4">
      <div className="max-w-2xl mx-auto text-center space-y-8">
        {/* Steam animation */}
        <div className="relative inline-block">
          <Coffee size={64} className="text-amber-800" />
          <div className="absolute -top-6 left-1/2 transform -translate-x-1/2">
            {Array.from({ length: counter }).map((_, i) => (
              <div
                key={i}
                className="absolute h-2 w-2 bg-amber-200 rounded-full animate-ping"
                style={{
                  left: `${(i - 1) * 10}px`,
                  animationDelay: `${i * 200}ms`,
                  animationDuration: '1.5s'
                }}
              />
            ))}
          </div>
        </div>

        <h1 className="text-4xl md:text-5xl font-bold text-amber-900 mb-4">
          Brewing Something Special
        </h1>

        <p className="text-xl text-amber-800 max-w-lg mx-auto leading-relaxed">
          Like a perfect cup of coffee, great things take time to prepare. 
          We're carefully crafting something amazing for you.
        </p>

        <div className="flex items-center justify-center space-x-6 text-amber-800">
          <div className="flex items-center space-x-2">
            <Timer className="w-6 h-6" />
            <span>Coming Soon</span>
          </div>
        </div>

        {/* Newsletter Signup */}
        <div className="mt-12 max-w-md mx-auto">
          <div className="flex items-center gap-2 bg-white rounded-lg p-2 shadow-md">
            <Mail className="text-amber-800 w-5 h-5 ml-2" />
            <input
              type="email"
              placeholder="Enter your email for updates"
              className="flex-1 p-2 outline-none text-amber-900 placeholder-amber-400"
            />
            <button className="bg-amber-800 text-white px-4 py-2 rounded-md hover:bg-amber-700 transition-colors">
              Notify Me
            </button>
          </div>
        </div>

        {/* Social Proof */}
        <p className="text-amber-700 mt-8">
          Join hundreds of others waiting for the launch
        </p>
      </div>
    </div>
  );
};

export default Portfolio;






// import React from 'react';
// import { Link } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import {
//   Github,
//   Linkedin,
//   MessageSquare,
//   Mail,
//   Code,
//   Rocket,
//   Target,
//   BicepsFlexed,
//   CircleCheckBig,
//   Workflow,
// } from 'lucide-react';
// import Profile_pic from '../images/Safwanashraf_pic.jpeg';
// import Ecommerce_pic from '../images/Ecommerce_pic.png';
// import Oidrob_pic from '../images/Oidrob_pic.png';
// import ToDo_pic from '../images/ToDo_pic.png';
// import SpanishDelight_pic from '../images/SpanishDelight1_pic.png';
// import CreativeTechBackground from './CreativeTechBackground';
// import MemoryGame from './MemoryGame';
// import NumberGame from './NumberGame';

// const Portfolio = () => {
//   const projects = [
//     {
//       title: 'Oidrob',
//       description:
//         'Oidrob is an innovative online tool for managing tasks and projects with real-time collaboration. Stay organized, track deadlines, and boost productivity in one seamless platform. Experience the future of teamwork and streamline your workflow with Oidrob!',
//       technologies: ['React.js', 'Tailwind CSS', 'Node.js', 'Socket.io', 'Git'],
//       githubLink: 'https://github.com/Safwanashraf/Oidrob',
//       demoLink: '#',
//       image: Oidrob_pic,
//     },
//     {
//       title: 'E-commerce Project',
//       description:
//         'A full-stack E-commerce application built with React.js for the frontend and Node.js with Express for the backend. It utilizes MongoDB for data storage and features user authentication, product management, and a shopping cart for a seamless shopping experience.',
//       technologies: ['React.js', 'Node.js', 'Express.js', 'MongoDB', 'JWT'],
//       githubLink: 'https://github.com/Safwanashraf/MERNCart',
//       demoLink: '#',
//       image: Ecommerce_pic,
//     },
//     {
//       title: 'Spanish Delight',
//       description:
//         'Spanish Delight is an interactive, comprehensive web application designed to make Spanish language learning engaging and accessible. The project combines educational courses, interactive games, and a user-friendly interface to provide an immersive language learning experience.',
//       technologies: ['React.js', 'TailwindCSS', 'Vercel'],
//       githubLink: 'https://github.com/Safwanashraf/Spanish-Delight',
//       demoLink: 'https://spanish-delight.vercel.app/',
//       image: SpanishDelight_pic,
//     },
//     {
//       title: 'ToDo Application',
//       description:
//         'A simple todo application built with React. This app allows users to create, update, and delete tasks effortlessly.',
//       technologies: ['React.js', 'ReactRouterDOM', 'UUID'],
//       githubLink: '#',
//       demoLink: '#',
//       image: ToDo_pic,
//     },
//     {
//       title: 'Oidrob',
//       description:
//         'Oidrob is an innovative online tool for managing tasks and projects with real-time collaboration. Stay organized, track deadlines, and boost productivity in one seamless platform. Experience the future of teamwork and streamline your workflow with Oidrob!',
//       technologies: ['React.js', 'Tailwind CSS', 'Node.js', 'Socket.io', 'Git'],
//       githubLink: 'https://github.com/Safwanashraf/Oidrob',
//       demoLink: '#',
//       image: Oidrob_pic,
//     },
//     {
//       title: 'E-commerce Project',
//       description:
//         'A full-stack E-commerce application built with React.js for the frontend and Node.js with Express for the backend. It utilizes MongoDB for data storage and features user authentication, product management, and a shopping cart for a seamless shopping experience.',
//       technologies: ['React.js', 'Node.js', 'Express.js', 'MongoDB', 'JWT'],
//       githubLink: 'https://github.com/Safwanashraf/MERNCart',
//       demoLink: '#',
//       image: Ecommerce_pic,
//     },
//     {
//       title: 'Spanish Delight',
//       description:
//         'Spanish Delight is an interactive, comprehensive web application designed to make Spanish language learning engaging and accessible. The project combines educational courses, interactive games, and a user-friendly interface to provide an immersive language learning experience.',
//       technologies: ['React.js', 'TailwindCSS', 'Vercel'],
//       githubLink: 'https://github.com/Safwanashraf/Spanish-Delight',
//       demoLink: 'https://spanish-delight.vercel.app/',
//       image: SpanishDelight_pic,
//     },
//     {
//       title: 'ToDo Application',
//       description:
//         'A simple todo application built with React. This app allows users to create, update, and delete tasks effortlessly.',
//       technologies: ['React.js', 'ReactRouterDOM', 'UUID'],
//       githubLink: '#',
//       demoLink: '#',
//       image: ToDo_pic,
//     },
//   ];

//   return (
//     <div className='relative min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-black text-white overflow-x-hidden'>
//       <CreativeTechBackground />
//       <div className='container mx-auto px-4 md:px-6 lg:px-8 py-8 md:py-12 lg:py-16 relative z-10 max-w-screen-2xl'>
//         {/* Hero Section */}
//         <motion.header
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 1 }}
//           className='text-center mb-12 md:mb-16 lg:mb-20'
//         >
//           <h1
//             className='text-4xl md:text-6xl lg:text-8xl font-black text-transparent bg-clip-text   
//                                   bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 mb-4   
//                                   tracking-tight leading-tight break-words'
//           >
//             Safwan Ashraf
//           </h1>
//           <motion.div
//             className='flex flex-wrap justify-center gap-2 md:gap-4 mb-8'
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             transition={{ delay: 0.5, duration: 1 }}
//           >
//             {[
//               { Icon: Code, text: 'Full Stack Developer' },
//               { Icon: Rocket, text: 'Innovation Architect' },
//               { Icon: Target, text: 'User-Centric Approach' },
//             ].map(({ Icon, text }) => (
//               <div
//                 key={text}
//                 className='flex items-center bg-white/10 px-3 md:px-4 py-1 md:py-2 rounded-full   
//                                                        text-xs md:text-sm lg:text-base'
//               >
//                 <Icon className='mr-1 md:mr-2 text-pink-400 w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6' />
//                 <span>{text}</span>
//               </div>
//             ))}
//           </motion.div>
//         </motion.header>

//         {/* About Section */}
//         <section className=' md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 mb-16'>
//           <div className='bg-white/10 rounded-2xl p-6 md:p-8 lg:p-10 flex flex-col'>
//             <h2
//               className='text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-transparent   
//                                       bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'
//             >
//               About Me
//             </h2>
//             <div className='flex flex-col xl:flex-row items-center mb-6 space-y-4 xl:space-y-0 xl:space-x-8'>
//               <img
//                 src={Profile_pic}
//                 alt='Safwan Ashraf'
//                 className='w-32 h-32 md:w-48 md:h-48 lg:w-56 lg:h-56 rounded-full object-cover   
//                                            border-4 border-purple-500 shrink-0'
//               />
//               <p
//                 className='text-sm md:text-base lg:text-lg leading-relaxed   
//                                           text-center md:text-left'
//               >
//                 I'm not just a developer—I'm a visionary problem solver dedicated to innovation. My passion lies in
//                 bridging the gap between technical solutions and business growth. I don't just code; I strategize,
//                 analyze, and deliver impactful results.
//                 <br />
//                 <br />
//                 By leveraging cutting-edge tools like AI, I enhance efficiency and ensure the highest quality outcomes.
//                 My unique perspective combines technical expertise with business acumen, allowing me to connect
//                 development with sales, marketing, and beyond.
//               </p>
//             </div>  

//             {/* Skills Section */}
//             <div className='flex flex-wrap justify-center gap-2 mb-5'>
//               {[
//                 'JavaScript',
//                 'ReactJS',
//                 'NodeJS',
//                 'ExpressJS',
//                 'ReactNative',
//                 'TailwindCSS',
//                 'MongoDB',
//                 'Socket.IO',
//               ].map(skill => (
//                 <span
//                   key={skill}
//                   className='flex items-center px-3 py-1 bg-white/20 rounded-full   
//                                                              text-xs md:text-sm lg:text-base'
//                 >
//                   <CircleCheckBig className='mr-1 md:mr-2 text-pink-400 w-4 h-4 md:w-5 md:h-5' />
//                   {skill}
//                 </span>
//               ))}
//             </div>

//             <div className='flex justify-center mt-5'>
//               <div
//                 className='flex items-center bg-white/10 px-3 md:px-4 py-1 md:py-2 rounded-full   
//                                             text-xs md:text-sm lg:text-base'
//               >
//                 <BicepsFlexed className='mr-1 md:mr-2 text-pink-400 w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6' />
//                 <span>Anything with JavaScript!</span>
//               </div>
//             </div>
//             <div className='grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 mt-16 mb-4'>
//             <NumberGame />
//             <MemoryGame />
//             </div>
//             <h2 className='leading-relaxed text-center text-2xl  font-bold mt-6 text-transparent   
//                                       bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'>Let’s transform challenges into opportunities together!</h2>
//           </div>
//         </section>

//         {/* Projects Section */}
//         <section className='grid grid-cols-1 relative group sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-8 lg:gap-10 mb-16'>
//           {projects.map((project, index) => (
//             <motion.div
//               key={index}
//               className='bg-white/10 rounded-2xl overflow-hidden relative' // Added 'relative' here
//               whileHover={{ scale: 1.05 }}
//             >
//               {' '}
//               <Link rel='noopener noreferrer' to={project.demoLink}>
//                 <img src={project.image} alt={project.title} className='w-full h-48 sm:h-56 lg:h-64 object-cover' />
//               </Link>
//               <div className='p-4 md:p-6 lg:p-8'>
//                 <h3
//                   className='text-xl md:text-2xl lg:text-3xl font-bold mb-2 md:mb-4 text-transparent   
//                                                bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'
//                 >
//                   {project.title}
//                 </h3>
//                 <p className='text-xs md:text-sm lg:text-base text-gray-300 mb-2 md:mb-4'>{project.description}</p>
//                 <div className='flex flex-wrap justify-center md:justify-start gap-2 mb-4'>
//                   {project.technologies.map(tech => (
//                     <span
//                       key={tech}
//                       className='flex items-center px-2 py-1 bg-white/20 rounded-full   
//                                         text-xs md:text-sm lg:text-base'
//                     >
//                       <Workflow className='mr-1 md:mr-2 text-pink-400 w-4 h-4 md:w-5 md:h-5' />
//                       {tech}
//                     </span>
//                   ))}
//                 </div>
//                 <div className='absolute top-2 right-2 p-2 bg-[#5A67D8] bg-opacity-80 rounded-md shadow-lg transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100 cursor-pointer'>
//                   <Link to={project.demoLink} rel='noopener noreferrer'>
//                     <h3 className='text-xs md:text-sm lg:text-base font-bold text-white'>Live</h3>
//                   </Link>
//                 </div>
//               </div>
//             </motion.div>
//           ))}
//         </section>

//         {/* Footer Section */}
//         <footer className='text-center bg-white/10 rounded-2xl p-6 md:p-8 lg:p-10'>
//           <h2
//             className='text-2xl md:text-3xl lg:text-4xl font-bold mb-6 md:mb-8 text-transparent   
//                                   bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'
//           >
//             Let's Connect & Collaborate
//           </h2>

//           <div className='flex justify-center gap-4 md:gap-8 mb-6 md:mb-8'>
//             {[
//               { Icon: Mail, link: 'mailto:1.safwanashraf@gmail.com', color: 'text-pink-400' },
//               { Icon: Github, link: 'https://github.com/Safwanashraf', color: 'text-white' },
//               { Icon: Linkedin, link: 'https://www.linkedin.com/in/safwan-ashraf/', color: 'text-blue-400' },
//               {
//                 Icon: MessageSquare,
//                 link: 'https://wa.me/919544558325?text=Hi,How-can-I-help-you!',
//                 color: 'text-sky-400',
//               },
//             ].map(({ Icon, link, color }) => (
//               <motion.a
//                 key={link}
//                 href={link}
//                 whileHover={{ scale: 1.2, rotate: 360 }}
//                 whileTap={{ scale: 0.9 }}
//                 className={`${color} text-2xl md:text-4xl lg:text-5xl hover:text-white transition-all duration-300`}
//               >
//                 <Icon strokeWidth={1.5} />
//               </motion.a>
//             ))}
//           </div>

//           <p className='text-xs md:text-sm lg:text-base text-gray-400'>
//             © 2024 Safwan Ashraf. Crafted with passion and innovative technology.
//           </p>
//         </footer>
//       </div>
//     </div>
//   );
// };

// export default Portfolio;
